import React from 'react';
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
const Bolas = ({misionV}) => {
    const { image} = useStaticQuery(graphql`
    query qBolas{
      image: file(relativePath: { eq: "circuloVacio.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 250, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { clase,imagenB,altI,tituloH2, parrafoP}=misionV
  const bkgImage = [image.sharp.fluid]
    return (
        <BackgroundImage className={clase} fluid={bkgImage}>
           <h2 className="titulo__mision-vision">{tituloH2}</h2>
            <p className="parrafo__mision-vision">
             {parrafoP}
            </p>
          
            <Img className="imagen__bola-mision" fluid={imagenB} alt={altI} />
   
        </BackgroundImage>
    );
};

export default Bolas;