import React from 'react';
import Layout from "../components/layout"
import Bolas from '../components/Bolas'
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import BGImage from '../components/Background'
export const query = graphql`
  query {
    image: file(relativePath: { eq: "misionvisionFondo.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 1105, maxHeight: 537) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "mad-mision.png" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 70, maxHeight: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "mad-vision.png" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 70, maxHeight: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
  }
`
const MisionVision = ({ data }) => {
  
  const bkgImage = data.image.sharp;
  const iMision = data.image2.sharp;
  const iVision = data.image3.sharp;
  const info=[{
    clase:"bola primeraBola", 
     tituloH2:"Misión",
     parrafoP:"Nuestra misión es dar soluciones y respuestas desarrolladas acorde a los requerimientos del cliente de forma rápida y eficiente",
     imagenB: iMision.fluid,  
     altI:"Misión",
  }
    ,{
      clase:"bola segundaBola",
       tituloH2:"Visión",
       parrafoP:"Crear un ambiente en el que los objetos cotidianos están interconectados a través de Internet, haciendo la vida más sencilla, con un  desarrollo y  gestión de los recursos disponibles.",
       imagenB: iVision.fluid,     
       altI:"Visión",
    }];
  
  return (
    <Layout>
      <Seo title="Misión y Visión, Madtronix Peru" description="Misión y Visión de nuestro Servicio" siteUrl="https://madtronix-peru.com/misionvision/" />
      <BGImage ImagenFluid={bkgImage.fluid} ClaseBG="backgroundC"   >

        <div className="misionVision" style={{ alignItems: "center", paddingBottom:"16rem" }} >
        {
               info.map((misionV,index)=>
                 (<Bolas key={index} misionV={misionV}/>)
               )
             }     
        </div>
      </BGImage>


    </Layout>
  );
};

export default MisionVision;